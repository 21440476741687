import * as ActionTypes from "../Actions/ActionTypes";

const initialState = {
    sispOpen: false,
    error: null
};

export default function (state, action) {
    if (state === undefined) {
        state = initialState;
    }

    switch (action.type) {
        case ActionTypes.OPEN_SUPPORT_SISP:
            return Object.assign({}, state, {
                sispOpen: true
            });
        case ActionTypes.CLOSE_SUPPORT_SISP:
            return Object.assign({}, state, {
                sispOpen: false,
                error: null
            });
        default:
            return state;
    }
}