'use strict';

import { Sisp } from '@sprint/sprint-react-components';
import React from 'react';
import { connect } from 'react-redux';
import AlertBanner, { AlertLevel } from '../../CommonComponents/AlertBanner/AlertBanner';
import SispLoading from '../../CommonComponents/Sisp/SispLoading';
import { loadClient } from '../../Entities/Client/Actions';
import { closeSisp } from './Actions/Actions';

class Support extends React.Component {
    componentDidMount() {
        if (this.props.client === undefined) this.props.dispatch(loadClient());
    }

    close() {
        this.props.dispatch(closeSisp());
    }

    emailInfo() {
        if (!this.props.client.supportEmailInvisible()) {
            if (this.props.client.supportEmailEnabled()) {
                return (
                    <div>
                        <h5>Email Support</h5>
                        <p>Got any questions? Drop us an email and we'll be happy to help.</p>
                        <p>
                            <strong>
                                <a href={'mailto:' + this.props.client.company.emailSupport}>
                                    {this.props.client.company.emailSupport}
                                </a>
                            </strong>
                        </p>
                    </div>
                );
            } else {
                return (
                    <div>
                        <h5>Email Support</h5>
                        <p>
                            Your current {this.props.client.company.dictionaryFromKey('general', 'app_name')} plan does
                            not include email support.
                        </p>
                    </div>
                );
            }
        }
    }

    telephoneInfo() {
        if (!this.props.client.supportTelephoneInvisible()) {
            if (this.props.client.supportTelephoneEnabled()) {
                return (
                    <div>
                        <h5>Telephone Support</h5>
                        <p>Anything you'd like to discuss? Give us a call on the number below.</p>
                        <p>
                            <strong>{this.props.client.company.telephone}</strong>
                        </p>
                    </div>
                );
            } else {
                return (
                    <div>
                        <h5>Telephone Support</h5>
                        <p>
                            Your current {this.props.client.company.dictionaryFromKey('general', 'app_name')} plan does
                            not include telephone support.
                        </p>
                    </div>
                );
            }
        }
    }

    supportInfo() {
        return (
            <div>
                <h5>Knowledge Base</h5>
                <p>
                    Access our in-depth knowledge base to learn how to make the most of your{' '}
                    {this.props.client.company.dictionaryFromKey('general', 'app_name')} account.
                </p>
                <strong>
                    <a href={this.props.client.company.kbBaseUrl} target="_blank">
                        {this.props.client.company.kbBaseUrl}
                    </a>
                </strong>
                {this.emailInfo()}
                {this.telephoneInfo()}
            </div>
        );
    }

    render() {
        const showLoader = this.props.client === undefined;
        return (
            <Sisp isOpen={this.props.sispOpen} onCancel={this.close.bind(this)}>
                <h4>Support</h4>
                {this.props.error ? <AlertBanner alertLevel={AlertLevel.DANGER}>{this.props.error}</AlertBanner> : null}
                {showLoader ? <SispLoading /> : this.supportInfo()}
            </Sisp>
        );
    }
}

function select(state) {
    return {
        sispOpen: state.main.sispOpen,
        client: state.client.client,
        error: state.main.error,
    };
}

export default connect(select)(Support);
