'use strict';

import * as ActionTypes from './ActionTypes';

export function openSisp() {
    return {
        type: ActionTypes.OPEN_SUPPORT_SISP
    }
}

export function closeSisp() {
    return {
        type: ActionTypes.CLOSE_SUPPORT_SISP
    }
}
