'use strict';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {applyMiddleware, createStore} from 'redux';
import rootReducer from './rootReducer';
import ThunkMiddleware from 'redux-thunk';

import Support from "./Support";
import {openSisp} from "./Actions/Actions";

const store = createStore(rootReducer, applyMiddleware(ThunkMiddleware));

$('.js-show-support-sisp').on('click', function(e) {
    e.preventDefault();
    store.dispatch(openSisp());
});

const el = document.getElementById('SupportSISP');

if (el) {
    const root = createRoot(el);
    root.render(
        <Provider store={store}>
            <Support />
        </Provider>
    );
}
